
import { Vue, Component } from 'vue-property-decorator';
import userx from '@/store/modules/userx';
import { Route, RawLocation } from 'vue-router';
import continueToUrl from '@/helpers/continueToUrl';
import { EditState } from '@/store/models.def';
import EmailInput from '@/components/inputs/EmailInput.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import { PasswordStrength } from '@/components/inputs/password.def';
import StateButton from '@/components/inputs/StateButton.vue';

@Component({
	components: {
		EmailInput,
		PasswordInput,
		StateButton,
	},
})
export default class Login extends Vue {
	public email = '';
	public password = '';
	public loginError = '';
	public validateNow = false;
	public loginState: EditState = 'active';

	public passwordStrength = PasswordStrength.Medium;
	public continueUrl = '/';

	public get saveEmailLabel() {
		const labels = {
			active: 'Sign In',
			sending: 'Signing In...',
			done: 'Success',
		};
		return labels[this.loginState];
	}

	// a = (b:any) => this.$store.dispatch('a',b)
	public async login() {
		this.validateNow = true;
		if (this.email !== null && this.password !== null) {
			this.loginState = 'sending';

			try {
				const response = await userx.signIn({
					email: this.email,
					password: this.password,
				});
				if (response.success) {
					this.loginState = 'done';
					setTimeout(() => continueToUrl(this.continueUrl), 1000);
				} else {
					this.loginState = 'active';
					this.loginError = response.errorMessage || 'Unknown error. Please contact technical team for support';

					switch (response.errorCode) {
						case 'auth/user-not-found':
							this.loginError =
								'User record not found. Click <a style="text-decoration: underline" href="https://bi.sql.com.my/#/register">here</a> to register a new account with us.';
							break;
						case 'auth/wrong-password':
							this.loginError =
								'Authentication failed, please check your current credentials.';
							break;
						default:
							this.loginError = response.errorMessage || 'Unknown error. Please contact technical team for support';
							break;
					}
				}
    console.log(response.errorMessage);
			} catch (error) {
				this.loginError = error || '';
    console.log(error);
			}
		}
	}

	public beforeRouteEnter(
		to: Route,
		from: Route,
		next: (to?: RawLocation | false | ((vm: Login) => any) | void) => void,
	): void {
		next((vm) => {
			vm.continueUrl = to.query.continueUrl
				? (to.query.continueUrl as string)
				: from.fullPath;
			vm.email = to.query.email ? (to.query.email as string) : '';
		});
	}
	public beforeRouteUpdate(
		to: Route,
		from: Route,
		next: (to?: RawLocation | false | ((vm: Login) => any) | void) => void,
	): void {
		next();
		if (to.query.continueUrl) {
			this.continueUrl = to.query.continueUrl as string;
		}
	}
}
